import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { NavigationServiceType } from '@service/navigation';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let ThanksView = class ThanksView extends Vue {
    beforeMount() {
        if (!this.hasSubscription) {
            this.$router.replace('/');
        }
    }
    get hasSubscription() {
        return this.navigationService.getSubscriptionButtonState();
    }
    /**
     * The image to render.
     */
    get imgSrc() {
        return require('../assets/images/soldier.svg');
    }
    /**
     * Handles the `@click` event on the CTA button.
     */
    onCtaButtonClick() {
        this.redirectToHomepage();
    }
    /**
     * Redirects the User to homepage.
     */
    redirectToHomepage() {
        this.$router.push('/');
    }
};
__decorate([
    Inject(NavigationServiceType)
], ThanksView.prototype, "navigationService", void 0);
ThanksView = __decorate([
    Component({
        name: 'ThanksView',
        metaInfo() {
            return { title: this.$t('modules.subscription.views.ThanksView.meta.title') };
        }
    })
], ThanksView);
export { ThanksView };
export default ThanksView;
