// Copyright © 2021 Move Closer
import { Step } from '../contracts';
import PaymentMethod from '../components/PaymentMethod/PaymentMethod.vue';
import Status from '../components/Status/Status.vue';
import Summary from '../components/Summary/Summary.vue';
/**
 * Registry that binds the `EStep` with the corresponding Vue component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const stepComponentRegistry = {
    [Step.PaymentMethod]: PaymentMethod,
    [Step.Status]: Status,
    [Step.Summary]: Summary
};
