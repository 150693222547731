import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { Screen } from '@component/Screen';
import { SubscriptionHeader } from '../components/SubscriptionHeader.vue';
import { SubscriptionType } from '../components/SubscriptionType/SubscriptionType.vue';
import { StepView } from './abstract/StepView';
import { BenefitsBanner } from '../components/BenefitsBanner.vue';
import { FAQSection } from '../components/FAQSection.vue';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl> (edited)
 * @author Mateusz Twardowski <mateusz.twardowski@movecloser.pl> (refactor)
 */
let ChooseProductView = class ChooseProductView extends StepView {
    constructor() {
        super(...arguments);
        this.isUserRequired = false;
        this.shouldShowLogin = false;
    }
    nextStep() {
        if (this.subscription) {
            this.$router.push({
                name: 'subscription.choose.payment',
                params: {
                    term: this.subscription.id,
                    first: '1'
                }
            });
        }
    }
};
ChooseProductView = __decorate([
    Component({
        name: 'ChooseProductView',
        components: {
            BenefitsBanner,
            FAQSection,
            Screen,
            SubscriptionType,
            SubscriptionHeader
        },
        metaInfo() {
            return { title: this.$t('_.chooseProduct') };
        }
    })
], ChooseProductView);
export { ChooseProductView };
export default ChooseProductView;
