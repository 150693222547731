import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let Benefits = class Benefits extends Vue {
};
Benefits = __decorate([
    Component({ name: 'Benefits' })
], Benefits);
export { Benefits };
export default Benefits;
