// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function fallbackCallback() {
    // eslint-disable-next-line no-console
    console.warn('[Step]: Callback not specified but used.');
}
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function fallbackUpdateCallback() {
    // eslint-disable-next-line no-console
    console.warn('[Step]: Update callback not specified but used.');
}
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let Step = class Step extends Vue {
    constructor() {
        super(...arguments);
        this.promotion = null;
    }
    updateHeight() {
        this.$nextTick(() => this.$emit('recalcHeight'));
    }
};
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], Step.prototype, "isUpgrade", void 0);
__decorate([
    Prop({ type: Function, required: false, default: () => (fallbackCallback) })
], Step.prototype, "goNext", void 0);
__decorate([
    Prop({ type: String, required: false })
], Step.prototype, "goNextLabel", void 0);
__decorate([
    Prop({ type: Function, required: false, default: () => (fallbackUpdateCallback) })
], Step.prototype, "onSubscriptionUpdate", void 0);
__decorate([
    Prop({ type: Function, required: false, default: () => (fallbackUpdateCallback) })
], Step.prototype, "onPromotionUpdate", void 0);
__decorate([
    Prop({ type: Object, required: false, default: null })
], Step.prototype, "subscription", void 0);
Step = __decorate([
    Component
], Step);
export { Step };
