import { __decorate } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import { Alert, AlertTheme } from '@component/Alert';
import { Inject } from '@plugin/inversify';
import { RadioBox } from '@component/RadioBox';
import { Screen } from '@component/Screen';
import { extractDriverFromOption, getOptionConfig, transactionDriverMap } from './PaymentMethod.helpers';
import { TransactionsRepositoryType } from '../../contracts';
import { Step } from '../abstracts/Step';
import { ConnectorErrors } from '@exception/connector-errors';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let PaymentMethod = class PaymentMethod extends Step {
    constructor() {
        super(...arguments);
        this.alertTheme = AlertTheme.Danger;
        this.errors = [];
        this.isActing = false;
        this.model = null;
    }
    get hasErrors() {
        return this.errors.length > 0;
    }
    /**
     * Options available for the User to select from.
     */
    get options() {
        if (!this.subscription) {
            return [];
        }
        return transactionDriverMap(this.subscription.type, this.subscription.renewable)
            .map((driver, index) => getOptionConfig(`${index}`, driver));
    }
    async createTransaction() {
        if (this.isActing) {
            return;
        }
        const driver = extractDriverFromOption(this.model);
        this.isActing = true;
        this.errors = [];
        if (this.isUpgrade) {
            localStorage.setItem('subscriptionDriver', driver !== null && driver !== void 0 ? driver : '');
            this.goNext();
            return;
        }
        try {
            let force = false;
            if (this.subscription.transaction) {
                force = true;
            }
            const token = await this.transactions.register(this.subscription.id, driver, force);
            if (!token.length) {
                this.alertTheme = AlertTheme.Danger;
                this.errors = ['Nieudana rejestracja płatności'];
                return;
            }
            this.goNext();
        }
        catch (e) {
            switch (e.status) {
                case ConnectorErrors.Validation:
                    this.alertTheme = AlertTheme.Warning;
                    this.errors = ['Wybierz metodę płatności'];
                    break;
                default:
                    this.alertTheme = AlertTheme.Danger;
                    this.errors = [e.message];
            }
            this.updateHeight();
        }
        this.isActing = false;
    }
    onSubscriptionLoad() {
        if (this.subscription) {
            this.$nextTick(() => {
                var _a;
                this.model = this.options.length ? this.options[0] : null;
                if (this.options.length === 1 && ((_a = this.$route.params) === null || _a === void 0 ? void 0 : _a.first) === '1') {
                    this.createTransaction();
                }
            });
        }
    }
};
__decorate([
    Inject(TransactionsRepositoryType)
], PaymentMethod.prototype, "transactions", void 0);
__decorate([
    Watch('subscription')
], PaymentMethod.prototype, "onSubscriptionLoad", null);
PaymentMethod = __decorate([
    Component({
        name: 'PaymentMethod',
        components: {
            Alert,
            RadioBox,
            Screen
        },
        created() {
            if (this.subscription) {
                this.onSubscriptionLoad();
            }
        }
    })
], PaymentMethod);
export { PaymentMethod };
export default PaymentMethod;
