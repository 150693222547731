import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
/**
 * @author Mateusz Twardowski <mateusz.twardowski@movecloser.pl>
 */
let SubscriptionHeader = class SubscriptionHeader extends Vue {
};
SubscriptionHeader = __decorate([
    Component({ name: 'SubscriptionHeader' })
], SubscriptionHeader);
export { SubscriptionHeader };
export default SubscriptionHeader;
