// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ModalType } from '@movecloser/front-core';
import { EModal } from '@contract/modal';
import { Inject } from '@plugin/inversify';
import { SubscriptionsRepositoryType } from '../../contracts';
import { stepComponentRegistry } from '../../maps/subscriptions';
import { NavigationServiceType } from '@service/navigation';
let StepView = class StepView extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.isUserRequired = true;
        this.shouldShowLogin = true;
        this.subscription = null;
        this.prevSubscription = null;
        this.promotion = null;
        this.translations = this.$t('modules.subscription.steps');
    }
    beforeMount() {
        if (!this.hasSubscription) {
            this.$router.replace('/');
        }
    }
    get hasSubscription() {
        return this.navigationService.getSubscriptionButtonState();
    }
    createStep(step, disabled = true, onToggle, goNext) {
        return {
            component: stepComponentRegistry[step],
            disabled: disabled,
            id: this.translations[step].heading,
            label: this.translations[step].heading,
            onToggle,
            props: {
                goNext: goNext || undefined,
                goNextLabel: this.translations[step].cta || '',
                onSubscriptionUpdate: this.onUpdated,
                onPromotionUpdate: this.onPromotionUpdated,
                subscription: this.subscription,
                isUpgrade: this.upgrade
            }
        };
    }
    get isUserLoggedIn() {
        return this.$store.getters['profile/isUserLoggedIn'];
    }
    get isWaitingForAuth() {
        return this.$store.getters['profile/isWaitingForAuth'];
    }
    async loadPeriod(id, warnIfPaid = true) {
        try {
            this.subscription = await this.subscriptions.load(id);
            if (this.subscription.promotion) {
                this.onPromotionUpdated(this.subscription.promotion);
            }
            if (this.upgrade) { // upgrade = change monthly subscription to annual
                this.subscription.set('renewable', false);
                this.prevSubscription = await this.subscriptions.load(this.$route.params.oldTerm);
            }
            else if (warnIfPaid && this.subscription.isPaid) {
                this.modalConnector.open(EModal.SubscriptionConflict, { id: this.subscription.id }, { closable: false });
                return;
            }
            this.isLoading = false;
        }
        catch (e) {
            this.$logger(e, 'warn');
        }
    }
    onInit() {
        // This method can be override by StepView.
    }
    onUpdated(subscription) {
        this.subscription = subscription;
    }
    onPromotionUpdated(promotion) {
        this.promotion = promotion;
    }
    onClose() {
        this.$router.back();
    }
    triggerLogin(redirect = true) {
        this.modalConnector.open(EModal.LogIn, {
            onSuccess: this.onInit,
            onClose: redirect ? this.onClose : null
        }, { closable: true });
    }
    onAuthProcessFinished(oldValue, newValue) {
        if (oldValue === newValue) {
            return;
        }
        if (!this.isUserLoggedIn) {
            this.triggerLogin(this.isUserRequired);
        }
        else {
            this.onInit();
        }
    }
};
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], StepView.prototype, "upgrade", void 0);
__decorate([
    Inject(ModalType)
], StepView.prototype, "modalConnector", void 0);
__decorate([
    Inject(SubscriptionsRepositoryType)
], StepView.prototype, "subscriptions", void 0);
__decorate([
    Inject(NavigationServiceType)
], StepView.prototype, "navigationService", void 0);
__decorate([
    Watch('isWaitingForAuth')
], StepView.prototype, "onAuthProcessFinished", null);
StepView = __decorate([
    Component({
        mounted() {
            if (this.shouldShowLogin && !this.isUserLoggedIn && !this.isWaitingForAuth) {
                this.triggerLogin(this.isUserRequired);
            }
        }
    })
], StepView);
export { StepView };
