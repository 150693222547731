import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { Screen } from '@component/Screen';
import { addOrdering } from '../helpers';
import { Cart } from '../components/Cart.vue';
import { StepView } from './abstract/StepView';
import { Step } from '../contracts';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let StatusCheckView = class StatusCheckView extends StepView {
    get isReady() {
        return this.isUserLoggedIn && !this.isLoading;
    }
    get steps() {
        return addOrdering([
            this.createStep(Step.PaymentMethod),
            this.createStep(Step.Summary),
            this.createStep(Step.Status, false)
        ]);
    }
    onInit() {
        this.loadPeriod(this.$route.params.term, false);
    }
};
StatusCheckView = __decorate([
    Component({
        name: 'StatusCheckView',
        components: { Cart, Screen },
        mounted() {
            if (this.isUserLoggedIn) {
                this.loadPeriod(this.$route.params.term, false);
            }
        },
        metaInfo() {
            return { title: this.$t('_.checkStatus') };
        }
    })
], StatusCheckView);
export { StatusCheckView };
export default StatusCheckView;
