import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { SiteServiceType } from '@service/site';
/**
 * @author Mateusz Twardowski <mateusz.twardowski@movecloser.pl>
 */
let FAQSection = class FAQSection extends Vue {
    get faqUrl() {
        return this.siteService.getFaqUrl();
    }
};
__decorate([
    Inject(SiteServiceType)
], FAQSection.prototype, "siteService", void 0);
FAQSection = __decorate([
    Component({
        name: 'FAQSection'
    })
], FAQSection);
export { FAQSection };
export default FAQSection;
