import { __decorate } from "tslib";
import { Component, Provide } from 'vue-property-decorator';
import { driverProcessComponents, UPDATE_COMPONENT_HEIGHT_KEY } from './Summary.config';
import { Step } from '../abstracts/Step';
import { PromotionProductType, TransactionDriver } from '@subscription/contracts';
import { CompanyDataForm } from '@subscription/components/CompanyDataForm';
import { PromoCode } from '@subscription/components/PromoCode/PromoCode.vue';
import { Inject } from '@plugin/inversify';
import { SiteServiceType } from '@service/site';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let Summary = class Summary extends Step {
    constructor() {
        super(...arguments);
        this.companyData = null;
        this.promoCodesConfig = null;
        this.promoCode = null;
        this.userData = null;
        this.productData = {
            id: this.subscription.id,
            kind: this.subscription.period,
            type: PromotionProductType.Subscription
        };
        this.products = [this.productData];
    }
    get meData() {
        if (!this.me) {
            return null;
        }
        return {
            ...this.me.companyData,
            firstName: this.me.firstName,
            lastName: this.me.lastName
        };
    }
    get driver() {
        var _a;
        if (this.isUpgrade) {
            const driver = localStorage.getItem('subscriptionDriver');
            if (!driver) {
                // todo: brzydko
                this.$router.push({ name: 'subscription.choose.payment.upgrade', params: { term: `${this.subscription.id}` } });
                throw new Error('Missing payment method');
            }
            return driver;
        }
        return ((_a = this.subscription.transaction) === null || _a === void 0 ? void 0 : _a.driver) || TransactionDriver.PayU;
    }
    get me() {
        return this.$store.getters['profile/me'];
    }
    get paymentComponent() {
        return driverProcessComponents[this.driver];
    }
    get paymentMethod() {
        var _a;
        if (!this.subscription) {
            return '';
        }
        return [
            this.$t(`modules.subscription.paymentDrivers.${(_a = this.subscription.transaction) === null || _a === void 0 ? void 0 : _a.driver}.label`)
        ].join(' ');
    }
    get subscriptionType() {
        if (!this.subscription) {
            return '';
        }
        return [
            this.$t(`modules.subscription.renewable.${this.subscription.renewable}`),
            '-',
            this.$t(`modules.subscription.period.${this.subscription.period}.oneTime`)
        ].join(' ');
    }
    get userEmail() {
        return this.me.email;
    }
    changePaymentMethod() {
        this.$router.push({
            name: 'subscription.choose.payment',
            params: { term: this.subscription.id }
        });
    }
    changeProduct() {
        this.$router.push({ name: 'subscription.choose.product' });
    }
    updateComponentHeight() {
        this.$emit('recalcHeight');
    }
    onPromoCodeToggle() {
        setTimeout(this.updateComponentHeight, 100);
    }
    onInvoiceToggle() {
        setTimeout(this.updateComponentHeight, 100);
    }
};
__decorate([
    Inject(SiteServiceType)
], Summary.prototype, "siteService", void 0);
__decorate([
    Provide(UPDATE_COMPONENT_HEIGHT_KEY)
], Summary.prototype, "updateComponentHeight", null);
Summary = __decorate([
    Component({
        name: 'Summary',
        components: {
            CompanyDataForm,
            PromoCode
        },
        mounted() {
            setTimeout(this.updateComponentHeight, 100);
            setTimeout(this.updateComponentHeight, 500); // additional check for height
        },
        created() {
            this.promoCodesConfig = this.siteService.getPromoCodesConfig();
        }
    })
], Summary);
export { Summary };
export default Summary;
