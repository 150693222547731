import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { Screen } from '@component/Screen';
import { addOrdering } from '../helpers';
import { Cart } from '../components/Cart.vue';
import { Step } from '../contracts';
import { StepView } from './abstract/StepView';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let ChoosePaymentView = class ChoosePaymentView extends StepView {
    get isReady() {
        return this.isUserLoggedIn && !this.isLoading;
    }
    get steps() {
        return addOrdering([
            this.createStep(Step.PaymentMethod, false, undefined, this.nextStep),
            this.createStep(Step.Summary)
        ]);
    }
    nextStep() {
        if (this.subscription) {
            this.$router.push({
                name: this.upgrade ? 'subscription.summary.upgrade' : 'subscription.summary',
                params: { term: this.subscription.id, oldTerm: this.$route.params.oldTerm }
            });
        }
    }
    onInit() {
        this.loadPeriod(this.$route.params.term);
    }
};
ChoosePaymentView = __decorate([
    Component({
        name: 'ChoosePaymentView',
        components: { Cart, Screen },
        mounted() {
            if (this.isUserLoggedIn) {
                this.loadPeriod(this.$route.params.term);
            }
        },
        metaInfo() {
            return { title: this.$t('_.choosePayment') };
        }
    })
], ChoosePaymentView);
export { ChoosePaymentView };
export default ChoosePaymentView;
