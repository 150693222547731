// Copyright © 2021 Move Closer
import RadioOptionLabel from './RadioOptionLabel/RadioOptionLabel.vue';
import { SubscriptionPeriod } from '../../contracts';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function mapPeriodToOrderInt(period) {
    switch (period) {
        case SubscriptionPeriod.Annual:
            return 2;
        case SubscriptionPeriod.Monthly:
            return 0;
        case SubscriptionPeriod.SemiAnnual:
            return 1;
        default:
            return 3;
    }
}
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const extractPayloadFromOption = (chosen) => {
    var _a;
    if (!chosen || typeof chosen.label === 'string' || !chosen.label.props || typeof ((_a = chosen.label.props) === null || _a === void 0 ? void 0 : _a.product) === 'undefined') {
        return {};
    }
    return chosen.label.props.product.toCreatePayload();
};
/**
 * Constructs the `IRadioOption` object for the given `optionId`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const getOptionConfig = (id, product) => ({
    id: `product-${id}`,
    label: {
        component: RadioOptionLabel,
        props: { product }
    },
    value: id
});
/**
 * Sorts products to display.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const sortProducts = (prev, next) => {
    if (!prev.renewable && next.renewable) {
        return 1;
    }
    if (prev.period === next.period) {
        const prevPrice = parseFloat(prev.price);
        const nextPrice = parseFloat(next.price);
        return prevPrice > nextPrice ? 1 : -1;
    }
    const prevPeriodRate = mapPeriodToOrderInt(prev.period);
    const nextPeriodRate = mapPeriodToOrderInt(next.period);
    return prevPeriodRate > nextPeriodRate ? 1 : -1;
};
