import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Callout } from '@component/Callout';
import { PromotionProductType } from '../contracts';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 *
 * TODO: @Stan here's your playground :)
 */
let Cart = class Cart extends Vue {
    constructor() {
        super(...arguments);
        this.stringPlaceholder = '---';
    }
    get hasDiscount() {
        var _a;
        return !!((_a = this.promotionProduct) === null || _a === void 0 ? void 0 : _a.message);
    }
    get period() {
        if (!this.subscription) {
            return this.stringPlaceholder;
        }
        return this.$t(`modules.subscription.period.${this.subscription.period}.${this.subscription.renewable ? 'renewal' : 'oneTime'}`);
    }
    get price() {
        if (!this.subscription) {
            return '00.00';
        }
        return this.subscription.price;
    }
    get total() {
        var _a, _b, _c;
        if (!this.subscription) {
            return '00.00';
        }
        return (_c = (_b = (_a = this.subscription.transaction) === null || _a === void 0 ? void 0 : _a.total) === null || _b === void 0 ? void 0 : _b.toFixed(2)) !== null && _c !== void 0 ? _c : this.subscription.price;
    }
    get product() {
        if (!this.subscription) {
            return this.stringPlaceholder;
        }
        return [
            this.$t(`modules.subscription.period.${this.subscription.period}.access`),
            this.$t(`modules.subscription.disclaimer.${this.subscription.renewable ? 'renewal' : 'oneTime'}`)
        ].join(' ');
    }
    get type() {
        if (!this.subscription) {
            return this.stringPlaceholder;
        }
        return this.$t(`modules.subscription.renewable.${this.subscription.renewable}`);
    }
    get promotionProduct() {
        if (!this.promotion) {
            return null;
        }
        return this.promotion.products.find(p => p.type === PromotionProductType.Subscription);
    }
};
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], Cart.prototype, "isUpgrade", void 0);
__decorate([
    Prop({ type: Object, required: false, default: null })
], Cart.prototype, "subscription", void 0);
__decorate([
    Prop({ type: Object, required: false, default: null })
], Cart.prototype, "promotion", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], Cart.prototype, "readonly", void 0);
Cart = __decorate([
    Component({
        name: 'Cart',
        components: { Callout }
    })
], Cart);
export { Cart };
export default Cart;
