import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { ModalType } from '@movecloser/front-core';
import { Alert, AlertTheme } from '@component/Alert';
import { ConnectorErrors } from '@exception/connector-errors';
import { EModal } from '@contract/modal';
import { Inject } from '@plugin/inversify';
import { RadioBox } from '@component/RadioBox';
import { Screen } from '@component/Screen';
import { extractPayloadFromOption, getOptionConfig, sortProducts } from './SubscriptionType.helpers';
import { SubscriptionsRepositoryType } from '../../contracts';
import { Step } from '../abstracts/Step';
import { SubscriptionBox } from '@subscription/components/SubscriptionType/SubscriptionBox';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let SubscriptionType = class SubscriptionType extends Step {
    constructor() {
        super(...arguments);
        this.alertTheme = AlertTheme.Danger;
        this.errors = [];
        this.isActing = false;
        this.isLoading = true;
        this.model = null;
        this.products = [];
    }
    get hasErrors() {
        return this.errors.length > 0;
    }
    get isUserLoggedIn() {
        return this.$store.getters['profile/isUserLoggedIn'];
    }
    get me() {
        return this.$store.getters['profile/me'];
    }
    get options() {
        return this.products.map((product, index) => getOptionConfig(`${index}`, product));
    }
    process() {
        this.createPeriod();
    }
    setModelAndProceed(product, index) {
        if (!this.isUserLoggedIn) {
            this.modalConnector.open(EModal.LogIn, {
                onSuccess: () => {
                    this.modalConnector.close();
                    this.setModelAndProceed(product, index);
                }
            }, { closable: true });
            return;
        }
        this.model = getOptionConfig(`${index}`, product);
        this.process();
    }
    async createPeriod(force = false) {
        this.isActing = true;
        this.errors = [];
        const payload = extractPayloadFromOption(this.model);
        try {
            const model = await this.subscriptions.create({
                ...payload,
                force
            });
            this.onSubscriptionUpdate(model);
            this.goNext();
        }
        catch (e) {
            switch (e.status) {
                case ConnectorErrors.Conflict:
                    if ('subscription' in e.payload && !force) {
                        this.modalConnector.open(EModal.SubscriptionConflict, {
                            id: e.payload.subscription,
                            onForce: () => this.createPeriod(true)
                        }, { closable: true });
                    }
                    else {
                        this.alertTheme = AlertTheme.Danger;
                        this.errors = [e.message];
                    }
                    break;
                case ConnectorErrors.Validation:
                    this.alertTheme = AlertTheme.Warning;
                    this.errors = ['Wybierz rodzaj subskrypcji'];
                    break;
                default:
                    this.alertTheme = AlertTheme.Danger;
                    this.errors = [e.message];
            }
            this.updateHeight();
        }
        this.isActing = false;
    }
    async loadPricing() {
        try {
            this.products = (await this.subscriptions.pricing()).sort(sortProducts);
            this.isLoading = false;
        }
        catch (e) {
            this.alertTheme = AlertTheme.Danger;
            this.errors = [e.message];
        }
        finally {
            this.updateHeight();
        }
    }
};
__decorate([
    Inject(ModalType)
], SubscriptionType.prototype, "modalConnector", void 0);
__decorate([
    Inject(SubscriptionsRepositoryType)
], SubscriptionType.prototype, "subscriptions", void 0);
SubscriptionType = __decorate([
    Component({
        name: 'SubscriptionType',
        components: {
            Alert,
            RadioBox,
            Screen,
            SubscriptionBox
        },
        created() {
            this.loadPricing();
        }
    })
], SubscriptionType);
export { SubscriptionType };
export default SubscriptionType;
