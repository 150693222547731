import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { Screen } from '@component/Screen';
import { addOrdering } from '../helpers';
import { Cart } from '../components/Cart.vue';
import { Step } from '../contracts';
import { StepView } from './abstract/StepView';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let SummaryView = class SummaryView extends StepView {
    get isReady() {
        return this.isUserLoggedIn && !this.isLoading;
    }
    get steps() {
        return addOrdering([
            this.createStep(Step.PaymentMethod, false, this.changePayment),
            this.createStep(Step.Summary, false)
        ]);
    }
    changePayment() {
        var _a;
        this.$router.push({
            name: this.upgrade ? 'subscription.choose.payment.upgrade' : 'subscription.choose.payment',
            params: { term: `${(_a = this.subscription) === null || _a === void 0 ? void 0 : _a.id}`, oldTerm: this.$route.params.oldTerm }
        });
        return false;
    }
    onInit() {
        this.loadPeriod(this.$route.params.term);
    }
};
SummaryView = __decorate([
    Component({
        name: 'SummaryView',
        components: { Cart, Screen },
        mounted() {
            if (this.isUserLoggedIn) {
                this.loadPeriod(this.$route.params.term);
            }
        },
        metaInfo() {
            return { title: this.$t('_.summary') };
        }
    })
], SummaryView);
export { SummaryView };
export default SummaryView;
