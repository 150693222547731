import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { Alert, AlertTheme } from '@component/Alert';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { TransactionsRepositoryType, TransactionStatus } from '../../contracts';
import { Step } from '../abstracts/Step';
import { ConnectorErrors } from '@exception/connector-errors';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let Status = class Status extends Step {
    constructor() {
        super(...arguments);
        this.alertTheme = AlertTheme.Danger;
        this.checkCount = 1;
        this.checkInterval = 2000;
        this.checkLimit = 15;
        this.errors = [];
        this.isChecking = true;
        this.status = TransactionStatus.Pending;
        this.TransactionStatus = TransactionStatus;
    }
    get hasErrors() {
        return this.errors.length > 0;
    }
    get isPaymentError() {
        return this.$route.query.error === '501';
    }
    async checkStatus() {
        try {
            const transaction = await this.transactions.status(this.$route.params.transaction);
            if (!this.isPaymentError && ![TransactionStatus.Fail, TransactionStatus.Success].includes(transaction.status)) {
                if (this.checkCount >= this.checkLimit) {
                    this.alertTheme = AlertTheme.Info;
                    this.isChecking = false;
                    this.errors = ['To może potrwać trochę dłużej. Prosimy, sprawdź status później.'];
                    return;
                }
                setTimeout(() => this.checkStatus(), this.checkInterval);
                this.checkCount++;
                return;
            }
            this.status = transaction.status;
            if (this.status === TransactionStatus.Success) {
                setTimeout(() => this.$router.push({ name: 'subscription.thanks' }), 1500);
            }
        }
        catch (e) {
            this.isChecking = false;
            switch (e.status) {
                case ConnectorErrors.Forbidden:
                    this.alertTheme = AlertTheme.Warning;
                    this.errors = ['Nie posiadasz uprawnień do sprawdzania statusu tej transakcji.'];
                    break;
                case ConnectorErrors.NotFound:
                case ConnectorErrors.ServerError:
                default:
                    this.alertTheme = AlertTheme.Danger;
                    this.errors = ['Wystąpił nieznany błąd.'];
            }
            this.updateHeight();
        }
    }
};
__decorate([
    Inject(TransactionsRepositoryType)
], Status.prototype, "transactions", void 0);
Status = __decorate([
    Component({
        name: 'Status',
        components: { Alert, Screen },
        mounted() {
            this.checkStatus();
        }
    })
], Status);
export { Status };
export default Status;
