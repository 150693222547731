import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SubscriptionType } from '../../../contracts';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let RadioOptionLabel = class RadioOptionLabel extends Vue {
    constructor() {
        super(...arguments);
        this.Type = SubscriptionType;
    }
    get period() {
        return this.$t(`modules.subscription.period.${this.product.period}.oneTime`);
    }
    get type() {
        return this.$t(`modules.subscription.renewable.${this.product.renewable}`);
    }
};
__decorate([
    Prop({ type: Object, required: true })
], RadioOptionLabel.prototype, "product", void 0);
RadioOptionLabel = __decorate([
    Component({ name: 'RadioOptionLabel' })
], RadioOptionLabel);
export { RadioOptionLabel };
export default RadioOptionLabel;
