import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SubscriptionPeriod, SubscriptionType } from '../../../contracts';
/**
 * @author Mateusz Twardowski <mateusz.twardowski@movecloser.pl>
 */
let SubscriptionBox = class SubscriptionBox extends Vue {
    constructor() {
        super(...arguments);
        this.Type = SubscriptionType;
        this.SubscriptionPeriod = SubscriptionPeriod;
    }
    get fullPrice() {
        if (!this.product.fullPrice) {
            return 0;
        }
        const fullPrice = parseFloat(this.product.fullPrice);
        if (fullPrice > 0) {
            return fullPrice.toFixed(2);
        }
        return 0;
    }
    get monthly() {
        switch (this.product.period) {
            case SubscriptionPeriod.Annual:
                return (parseFloat(this.product.price) / 12).toFixed(1) + '0';
            case SubscriptionPeriod.Monthly:
                return this.product.price;
            case SubscriptionPeriod.SemiAnnual:
                return (parseFloat(this.product.price) / 6).toFixed(1) + '0';
            default:
                return 0;
        }
    }
    get paymentMethod() {
        if (this.product.renewable) {
            return require('../../../assets/images/cards.svg');
        }
        return require('../../../assets/images/payu-blik-cards.svg');
    }
    get period() {
        return this.$t(`modules.subscription.period.${this.product.period}.oneTime`);
    }
    get saves() {
        if (!this.product.saves) {
            return 0;
        }
        return parseInt(this.product.saves);
    }
    get type() {
        return this.$t(`modules.subscription.renewable.${this.product.renewable}`);
    }
    emitProduct() {
        this.$emit('productPicked', this.product);
    }
};
__decorate([
    Prop({ type: Object, required: true })
], SubscriptionBox.prototype, "product", void 0);
SubscriptionBox = __decorate([
    Component({ name: 'SubscriptionBox' })
], SubscriptionBox);
export { SubscriptionBox };
export default SubscriptionBox;
