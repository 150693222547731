import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Benefits } from '../components/Benefits.vue';
/**
 * @author Mateusz Twardowski <mateusz.twardowski@movecloser.pl>
 */
let BenefitsBanner = class BenefitsBanner extends Vue {
    get imgSrc() {
        return require('@/assets/images/paywall-image.png');
    }
};
BenefitsBanner = __decorate([
    Component({
        name: 'BenefitsBanner',
        components: { Benefits }
    })
], BenefitsBanner);
export { BenefitsBanner };
export default BenefitsBanner;
